import Vue from "vue"
import locale from "./lib/locale"
import "./logic/registerServiceWorker"
import zh from "./locales/zh.json"
import App from "./pwa-app.vue"
import { postUpdateToIframeParent, state } from "./state"
import { setupBugTracker } from "./bugs"
import request from "./lib/request"

import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css'

// This will be done privacy conform, see bugs/README-BUGTRACKER.md
setupBugTracker()

// Electron specific i.e. Windows App will become a nicer modern window title and some other small features
if (
  navigator.userAgent.toLowerCase().indexOf(" electron/") > -1 &&
  window.beaker == null
) {
  console.log("Identified Electron")
  import(/* webpackChunkName: 'pwa-electron' */ "./pwa-electron").then()
  console.log("Handled Electron")
}

window.iOS = navigator?.platform?.match(/(iPhone|iPod|iPad)/i) != null
window.iPhone = navigator?.platform?.match(/(iPhone|iPod)/i) != null
if (window.iPhone) {
  console.log("Identified Phone of a native app")
}

Vue.config.productionTip = false

Vue.mixin({
  data() {
    return {
      state,
    }
  },
  watch: {
    state: {
      handler: postUpdateToIframeParent,
      deep: true,
    },
  },
  methods: {
    openExternalLink(event) {
      if (window.electron) {
        let href
        if (typeof event === "string") {
          href = event
        } else {
          let target = event?.target
          while (target && target?.href == null) {
            target = target.parentElement
          }
          href = target?.href
        }
        console.info("Open external link", event.target)
        if (href) {
          window.electron.shell.openExternal(href)
        }
        event.preventDefault()
        return false
      }
      return true
    },
  },
})

Vue.use(locale, {
  locales: {
    zh, // Thanks to https://github.com/Bruce-Ming
  },
})

Vue.prototype.$http = request
Vue.prototype.$layer = layer(Vue, {
    msgtime: 3,//目前只有一项，即msg方法的默认消失时间，单位：秒
})

window.$layer = Vue.prototype.$layer

Vue.directive('preventReClick', {
  inserted: function (el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 3000)
      }
      //console.log(el.disabled)
    })
  }
})

new Vue({
  render: (h) => h(App),
}).$mount("#app")

// Do some tests on the actual browser

if (localStorage?.test) {
  import(/* webpackChunkName: 'test' */ "./in-browser-test.js").then()
}
